import { useEffect, useState } from "react";
import mqtt from "mqtt";
import "./App.css";
import MachineLight from "./components/MachineLight";
import logo from "./logo-white.png";
import "react-data-grid/lib/styles.css";
import DataGrid from "react-data-grid";

import {
  AppBar,
  Box,
  Container,
  CssBaseline,
  GlobalStyles,
  ThemeProvider,
  Toolbar,
  Typography,
  createTheme,
} from "@mui/material";
import { AccessTimeRounded, ArrowForwardRounded, CloseRounded, DoneRounded } from "@mui/icons-material";
import Moment from "react-moment";

// ** MUI Imports

type OperationalProcess = {
  date: string;
  shiftHours: string;
  lineDownTime: number;
  lineWorkingTime: number;
};

type MessageType = {
  Kolileme: {
    Green: boolean;
    Red: boolean;
    Yellow: boolean;
  };
  Besleme: {
    Green: boolean;
    Red: boolean;
    Yellow: boolean;
  };
  Dolum: {
    Green: boolean;
    Red: boolean;
    Yellow: boolean;
  };
  Etiket: {
    Green: boolean;
    Red: boolean;
    Yellow: boolean;
  };
  Kutulama: {
    Green: boolean;
    Red: boolean;
    Yellow: boolean;
  };
  Line: {
    StopTimer: number;
    WorkingTimer: number;
    BottleEnter: number;
    BottleLeave: number;
  };
  Time: number;
};

export type Light = {
  Green: boolean;
  Red: boolean;
  Yellow: boolean;
};

function App() {
  const [kolilemeLights, setKolilemeLights] = useState<Light>({
    Green: false,
    Red: false,
    Yellow: false,
  });
  const [kutulamaLights, setKutulamaLights] = useState<Light>({
    Green: false,
    Red: false,
    Yellow: false,
  });
  const [etiketLights, setEtiketLights] = useState<Light>({
    Green: false,
    Red: false,
    Yellow: false,
  });
  const [dolumLights, setDolumLights] = useState<Light>({
    Green: false,
    Red: false,
    Yellow: false,
  });
  const [beslemeLights, setBeslemeLights] = useState<Light>({
    Green: false,
    Red: false,
    Yellow: false,
  });

  const [processTime, setProcessTime] = useState(0);
  const [totalErrorTime, setErrorTime] = useState(0);
  const [bottleEnter, setBottleEnter] = useState(0);
  const [bottleLeave, setBottleLeave] = useState(0);

  const [totalOperationTime, setTotalOperationTime] = useState(0);
  const [client, setClient] = useState<mqtt.MqttClient>();

  const [rows, setRows] = useState<OperationalProcess[]>([]);
  const [timeString, setTimeString] = useState<Date>(new Date());

  const websocketHost: string = process.env.REACT_APP_WEBSOCKET_HOST || "";
  const websocketPort: number =
    Number(process.env.REACT_APP_WEBSOCKET_PORT) || 8083;
  const websocketPath: string = process.env.REACT_APP_WEBSOCKET_PATH || "";
  const websocketUserName: string =
    process.env.REACT_APP_WEBSOCKET_USERNAME || "";
  const websocketPassword: string =
    process.env.REACT_APP_WEBSOCKET_PASSWORD || "";

  function padTo2Digits(num: number) {
    return num.toString().padStart(2, "0");
  }

  function convertMsToTime(seconds: number) {
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;

    // 👇️ If you don't want to roll hours over, e.g. 24 to 00
    // 👇️ comment (or remove) the line below
    // commenting next line gets you 24:00:00 instead of 00:00:00
    // or 36:15:31 instead of 12:15:31, etc.
    hours = hours % 24;

    return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
      seconds
    )}`;
  }

  useEffect(() => {
    setClient(
      mqtt.connect(websocketHost, {
        path: websocketPath,
        port: websocketPort,
        username: websocketUserName,
        password: websocketPassword,
      })
    );
  }, []);

  useEffect(() => {
    setTimeString(new Date(Number(processTime) * 1000));
  }, [processTime]);

  useEffect(() => {
    if (client) {
      client.on("connect", () => {
        console.log("Connected");
        client.subscribe("test", { qos: 0 }, (error) => {
          if (error) {
            console.log("Subscribe to topics error", error);
            return;
          }
        });
        client.subscribe("testTime", { qos: 0 }, (error) => {
          if (error) {
            console.log("Subscribe to topics error", error);
            return;
          }
        });
      });
      client.on("error", (err) => {
        console.error("Connection error: ", err);
        client.end();
      });
      client.on("reconnect", () => {
        console.log("Reconnecting");
      });
      client.on("message", (topic, message) => {
        const payload = { topic, message: JSON.parse(message.toString()) };

        if (topic === "test") {
          const message: MessageType = payload.message;

          console.log(message.Dolum);

          const time = payload.message.time;

          setProcessTime(time);

          if (message.Kolileme) {
            setKolilemeLights(message.Kolileme);
          }

          if (message.Kutulama) {
            setKutulamaLights(message.Kutulama);
          }

          if (message.Etiket) {
            setEtiketLights(message.Etiket);
          }

          if (message.Dolum) {
            setDolumLights(message.Dolum);
          }

          if (message.Besleme) {
            setBeslemeLights(message.Besleme);
          }

          setProcessTime(message.Time);
          setTotalOperationTime(message.Line.WorkingTimer);
          setErrorTime(message.Line.StopTimer);

          setBottleEnter(message.Line.BottleEnter)
          setBottleLeave(message.Line.BottleLeave)
        }
      });
    }
  }, [client]);

  useEffect(() => {
    const allRows = [];
    allRows.unshift({
      date: "26.12.2023 Salı",
      shiftHours: "20:00 - 08:00",
      lineDownTime: 13987,
      lineWorkingTime: 29213, //25152538 - 06:59:12
    });
    allRows.unshift({
      date: "27.12.2023 Çarşamba",
      shiftHours: "08:00 - 20:00",
      lineDownTime: 15739,
      lineWorkingTime: 27461,
    });
    allRows.unshift({
      date: "27.12.2023 Çarşamba",
      shiftHours: "20:00 - 08:00",
      lineDownTime: 16253,
      lineWorkingTime: 26947,
    });
    allRows.unshift({
      date: "28.12.2023 Perşembe",
      shiftHours: "08:00 - 20:00",
      lineDownTime: 10180,
      lineWorkingTime: 33020,
    });
    setRows(allRows);
  }, []);

  const defaultTheme = createTheme({
    typography: {
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  });
  const columns = [
    { key: "date", name: "Tarih" },
    { key: "shiftHours", name: "Vardiya Saatleri" },
    {
      key: "lineDownTime",
      name: "Duruş Süresi",
      renderCell(props: any) {
        return convertMsToTime(props.row.lineDownTime);
      },
    },
    {
      key: "lineWorkingTime",
      name: "Çalışma Süresi",
      renderCell(props: any) {
        return convertMsToTime(props.row.lineWorkingTime);
      },
    },
  ];

  return (
    <ThemeProvider theme={defaultTheme}>
      <div style={{ display: "block", maxWidth: "100vw !important" }}>
        <GlobalStyles
          styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
        />
        <CssBaseline />
        <AppBar position="static" color="transparent" elevation={0}>
          <Toolbar sx={{ flexWrap: "wrap" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                alignItems: "center",
                alignContent: "center",

                mt: 3.5,
                mb: 2,
                ml: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  flexDirection: "column",
                }}
              >
                <img
                  src={logo}
                  style={{
                    height: 36,
                  }}
                  alt="logo"
                />
                <Typography
                  sx={{
                    color: "#FBFCFC !important",
                    fontWeight: 400,
                    fontSize: "0.85rem",
                    pr: 1,
                  }}
                >
                  Coding For Life
                </Typography>
              </Box>
              <Box
                sx={{
                  minWidth: 110,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: 1,
                  borderRadius: 2,
                  backgroundColor: "rgba(2, 224, 164, 0.17)",
                  color: "rgb(2, 224, 164)",
                  border: "1px solid rgb(2, 224, 164)",
                }}
              >
                <Box mb={1} ml={1}>
                  <span className="heartbeat"></span>
                  <span className="dot"></span>
                </Box>
                <Box>
                  <Moment className="desc" format="HH:mm:ss">
                    {timeString}
                  </Moment>
                </Box>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        <Box
          component={"main"}
          sx={{
            width: "100vw !important",
            height: "100vh !important",
            paddingBottom: 15,
            overflow: "auto",
            position: "relative",
          }}
        >
          <Container disableGutters maxWidth={false} sx={{ pt: 8, pb: 6 }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <div
                className="card"
                style={{
                  padding: 16,
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: "1.7rem",
                      color: "#FBFCFC !important",
                    }}
                  >
                    Şurup Hattı
                  </Typography>
                </Box>
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  flexWrap="wrap"
                  alignItems="center"
                >
                  <div
                    className="card"
                    style={{
                      padding: 16,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: 42,
                          p: 0,
                          height: 42,
                          mr: 1.5,
                          display: "flex",
                          borderRadius: "50%",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "rgba(255, 76, 81, 0.17)",
                          color: "rgba(255, 76, 81)",
                          border: "1px solid rgba(255, 76, 81)",
                        }}
                      >
                        <AccessTimeRounded />
                      </Box>
                      <Box sx={{
                        display: 'flex',
                        justifyContent: "flex-end",
                        flexDirection: 'column',
                        alignItems: 'flex-end'
                      }}>
                        <Typography>Duruş Süresi</Typography>
                        <Typography
                          sx={{
                            fontFamily: "inherit",
                            fontWeight: 700,
                            fontSize: "2rem",
                            lineHeight: 2,
                            color: "#FBFCFC !important",
                          }}
                        >
                          {convertMsToTime(totalErrorTime)}
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                  <div
                    className="card"
                    style={{
                      padding: 16,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: 42,
                          p: 0,
                          height: 42,
                          mr: 1.5,
                          display: "flex",
                          borderRadius: "50%",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "rgba(2, 224, 164, 0.17)",
                          color: "rgb(2, 224, 164)",
                          border: "1px solid rgb(2, 224, 164)",
                        }}
                      >
                        <AccessTimeRounded />
                      </Box>
                      <Box sx={{
                        display: 'flex',
                        justifyContent: "flex-end",
                        flexDirection: 'column',
                        alignItems: 'flex-end'
                      }}>
                        <Typography>Çalışma Süresi</Typography>
                        <Typography
                          sx={{
                            fontFamily: "inherit",
                            fontWeight: 700,
                            fontSize: "2rem",
                            lineHeight: 2,
                            color: "#FBFCFC !important",
                          }}
                        >
                          {convertMsToTime(totalOperationTime)}
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                </Box>
              </div>
            </Box>
   
          </Container>
          <Container maxWidth={"lg"}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                paddingBottom: 5,
                position: "relative",
                alignItems: "center",
              }}
            >
              <MachineLight
                title="Kolileme"
                time={processTime}
                lights={kolilemeLights}
              />
              <Box sx={{
                display: {xs:'none', md: 'block'}
              }}>
              <div className="arrows-body">
                <div className="arrow"></div>
                <div className="arrow"></div>
                <div className="arrow"></div>
              </div>
              </Box>
              <MachineLight
                title="Kutulama"
                time={processTime}
                lights={kutulamaLights}
              />
              <Box sx={{
                display: {xs:'none', md: 'block'}
              }}>
              <div className="arrows-body">
                <div className="arrow"></div>
                <div className="arrow"></div>
                <div className="arrow"></div>
              </div>
              </Box>
              <MachineLight
                title="Etiket"
                time={processTime}
                lights={etiketLights}
              />
             <Box sx={{
                display: {xs:'none', md: 'block'}
              }}>
              <div className="arrows-body">
                <div className="arrow"></div>
                <div className="arrow"></div>
                <div className="arrow"></div>
              </div>
              </Box>
              <MachineLight
                title="Dolum"
                time={processTime}
                lights={dolumLights}
              />
             <Box sx={{
                display: {xs:'none', md: 'block'}
              }}>
              <div className="arrows-body">
                <div className="arrow"></div>
                <div className="arrow"></div>
                <div className="arrow"></div>
              </div>
              </Box>
              <MachineLight
                title="Şişe Besleme"
                time={processTime}
                lights={beslemeLights}
              />
            </Box>
          </Container>
          <Container
            sx={{
              maxHeight: 600,
              paddingBottom: 5,
            }}
          >
            <DataGrid
              style={{
                maxHeight: 490,
              }}
              columns={columns}
              rows={rows}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "space-between",
              }}
            >
              <Box
                sx={{
                  mb: 1,
                  height: 10,
                  mt: 1,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "inherit",
                    fontWeight: 500,
                    fontSize: "0.85rem",
                    lineHeight: 2,
                    color: "#f9f9f9",
                  }}
                  align="center"
                >
                  {"Copyright © "}
                  {new Date().getFullYear()} All Rights Reserved
                </Typography>
              </Box>
            </Box>
          </Container>
        </Box>
      </div>
    </ThemeProvider>
  );
}

export default App;
