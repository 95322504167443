import { Box, Typography } from "@mui/material";
import { Light } from "../App";

type MachineLightProps = {
  title: string;
  lights?: Light;
  time?: number;

};

const MachineLight = (
  props: MachineLightProps = {
    lights: {
      Green: false,
      Red: false,
      Yellow: false,
    },
    title: "",
  }
) => {
  const { title, lights } = props;

  return (
    <Box
      sx={{
        maxWidth: 130,
        margin: '16px auto',
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          fontFamily: "inherit",
          fontWeight: 600,
          fontSize: "17px",
          lineHeight: 2,
          marginBottom: 1,
          color: '#FBFCFC !important'
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: "#fff",
          borderRadius: 1.5,
          padding: 0.5,
          background: "#191a1a"
        }}
      >
        <Box
          sx={{
            width: 75,
            height: 75,
            borderRadius: 1,
            backgroundColor: lights?.Red ? "#ff3822" : "#2a2b2c",
            boxShadow: lights?.Red ? "0 0 75px 13px #ff3822" : "rgba(0, 0, 0, 0.16) 0px 1px 4px",
          }}
        ></Box>
        <Box
          sx={{
            width: 75,
            height: 75,
            borderRadius: 1,
            my: 0.5,
            backgroundColor: lights?.Yellow ? "#eeff03" : "#2a2b2c",
            boxShadow: lights?.Yellow ? "0 0 75px 13px #eeff03" : "rgba(0, 0, 0, 0.16) 0px 1px 4px",
          }}
        ></Box>
        <Box
          sx={{
            width: 75,
            height: 75,
            borderRadius: 1,
            backgroundColor: lights?.Green ? "#06ff59" : "#2a2b2c",
            boxShadow: lights?.Green ? "0 0 75px 13px #06ff59" :  "rgba(0, 0, 0, 0.16) 0px 1px 4px",
          }}
        ></Box>
      </Box>
    </Box>
  );
};

export default MachineLight;
